import { SortDirection } from '../../../../../utils/constants';
import { createSearchPageSlice } from '../../../../Generic/SearchPage/slices/searchPageSlice';

const filterFields = {
  FirstName: 'FirstName',
  LastName: 'LastName',
  Email: 'Email',
  Role: 'Role',
  UserType: 'UserType',
  Active: 'Active',
  Client: 'Client'
};

const initialFilterState = {
  FirstName: '',
  LastName: '',
  Email: '',
  Role: null,
  UserType: null,
  Active: true,
  Client: null,
};

const initialPageState = {
  page: 0,
  pageSize: 20,
  sortColumn: 'FirstName',
  sortDirection: SortDirection.Asc,
};

export const sliceName = 'userSearch';
const userSearchSlice = createSearchPageSlice({
  sliceName,
  filterFields,
  initialFilterState,
  initialPageState
});

export const userSearchActions = userSearchSlice.actions;
export default userSearchSlice.reducer;
