const enTranslation = {
  Action: 'Action',
  Certificate: 'Certificate',
  'Job Name': 'Job Name',
  Status: 'Status',
  Received: 'Received',
  'Due Date': 'Due Date',
  Completed: 'Completed',
  'Project Code': 'Project Code',
  '# Sample': '# Sample',
  'First Sample': 'First Sample',
  'Last Sample': 'Last Sample',
  'Sample Type': 'Sample Type',
  Client: 'Client',
  'PO Number': 'PO Number',
  '# Samples': '# Samples',
  'Start Date': 'Start Date',
  'Received On': 'Received On',
  Laboratory: 'Laboratory',
  Office: 'Office',
  'Order Number': 'Order Number',
  Project: 'Project',
  'Shipment ID': 'Shipment ID',
  Started: 'Started',
  'First Reported': 'First Reported',
  'Last Reported': 'Last Reported',
  'Job Description': 'Job Description',
  Analytes: 'Analytes',
  Units: 'Units',
  'Detection Limit': 'Detection Limit',
  Method: 'Method',
  InProgress: 'In-Progress',
  Scheduled: 'Scheduled',
  'Spec Met': 'Spec Met',
  'Out of Spec': 'Out of Spec',
  Total: 'Total',
  Maximize: 'Maximize',
  Minimize: 'Minimize',
  'Created On': 'Created On',
  'Job Status': 'Job Status',
  Location: 'Location',
  Supplier: 'Supplier',
  'Sample Name': 'Sample Name',
  'Job Number': 'Job Number',
  Sequence: 'Sequence',
  'Sample Location': 'Sample Location',
  Source: 'Source',
  Brand: 'Brand',
  'Date Received': 'Date Received',
  'Sampling Date': 'Sampling Date',
  'Report(s)': 'Report(s)',
  'SpecMet/NotMet': 'Spec Met/Not Met',
  Description: 'Description',
  Approved: 'Approved',
  'Site Country': 'Site Country',
  Job: 'Job',
  'Job Progress': 'Job Progress',
  Deliverables: 'Deliverables',
  'Laboratory Report': 'Laboratory Report',
  'Data Files': 'Data Files',
  Invoices: 'Invoices',
  'Other Documents': 'Other Documents',
  Site: 'Site',
  'Sample Date': 'Sample Date',
  'Component Age': 'Component Age',
  'Filter Changed': 'Filter Changed',
  'Sample Number': 'Sample Number',
  'Action Number': 'Action Number',
  'Action Owner': 'Action Owner',
  'Action Type': 'Action Type',
  'Action Status': 'Action Status',
  'Belongs To': 'Belongs To',
  'Record ID': 'Record ID',
  Created: 'Created',
  'Started On': 'Started On',
  'Started By': 'Started By',
  'Completed On': 'Completed On',
  'Client Name': 'Client Name',
  'Client Worksite': 'Client Worksite',
  'Client Address': 'Client Address',
  'Lab No': 'Lab No',
  'Received Date': 'Received Date',
  'Completion Date': 'Completion Date',
  'Tracking No': 'Tracking No',
  'Reference No': 'Reference No',
  'Work Order No': 'Work Order No',
  'PO No': 'PO No',
  'Make Up Oil Amount': 'Make Up Oil Amount',
  Port: 'Port',
  'IMO No': 'IMO No',
  'Engine Hours': 'Engine Hours',
  'Engine Type': 'Engine Type',
  'No of Cylinders': 'No of Cylinders',
  'Total No of Samples': 'Total No of Samples',
  'Date Sampled': 'Date Sampled',
  'Date Landed': 'Date Landed',
  'Date Reported': 'Date Reported',
  'Percent of MCR (PCT)': 'Percent of MCR (PCT)',
  'Max Speed rpm': 'Max Speed rpm',
  'Max Output KW': 'Max Output KW',
  'Max Output': 'Max Output',
  'Actual Speed rpm': 'Actual Speed rpm',
  'Actual Output KW': 'Actual Output KW',
  Asset: 'Asset',
  'Asset Part': 'Asset Part',
  Fluid: 'Fluid',
  AssetID: 'AssetID',
  'Asset Mfg': 'Asset Mfg',
  'Asset Model': 'Asset Model',
  'Asset Serial No': 'Asset Serial No',
  'Asset Worksite': 'Asset Worksite',
  'Asset Part Mfg': 'Asset Part Mfg',
  'Asset Part Model': 'Asset Part Model',
  'Asset Part Serial No': 'Asset Part Serial No',
  'Asset Part Type': 'Asset Part Type',
  Manufacturer: 'Manufacturer',
  Grade: 'Grade',
  Parameter: 'Parameter',
  Spec: 'Spec',
  Result: 'Result',
  'Result Unit': 'Result Unit',
  'Reported Date': 'Reported Date',
  'Sampled Date': 'Sampled Date',
  'Batch/Barcode No': 'Batch/Barcode No',
  Type: 'Type',
  Company: 'Company',
  Address: 'Address',
  Phone: 'Phone',
  Email: 'Email',
  Reports: 'Reports',
  Quantity: 'Quantity',
  'Sample Sequence': 'Sample Sequence',
  'Prep Code': 'Prep Code',
  'Analytical Package or Elements Requested': 'Analytical Package or Elements Requested',
  Controls: 'Controls',
  'File Name': 'File Name',
  'Inbound ID': 'Inbound ID',
  'Processed On': 'Processed On',
  'Ext System Code': 'Ext System Code',
  'Created On From': 'Created On From',
  'Created On To': 'Created On To',
  'Spec Met/ Not Met': 'Spec Met/ Not Met',
  Name: 'Name',
  Component: 'Component',
  Position: 'Position',
  Model: 'Model',
  'Serial Number': 'Serial Number',
  'Fuel Type': 'Fuel Type',
  'Oil Manufacturer': 'Oil Manufacturer',
  'Oil Brand': 'Oil Brand',
  'Oil Grade': 'Oil Grade',
  'Oil Capacity': 'Oil Capacity',
  'Oil Unit': 'Oil Unit',
  'Cooling System type': 'Cooling System type',
  Sample: 'Sample',
  'Next Sample': 'Next Sample',
  Frequency: 'Frequency',

  Nav_Menu_Home: 'Home',
  Nav_Menu_Dashboard_Mineral: 'Dashboard Mineral',
  Nav_Menu_Dashboard_Food: 'Dashboard Food',
  Nav_Menu_Dashboard_BVAQ: 'Dashboard BVAQ',
  Nav_Menu_Knowledge_Center: 'Knowledge Center',
  Nav_Menu_Trending: 'Trending',
  Nav_Menu_Jobs_And_Samples: 'Jobs & Samples',
  Nav_Menu_Jobs: 'Jobs',
  Nav_Menu_Jobs_Details: 'Job Details',
  Nav_Menu_Samples: 'Samples',
  Nav_Menu_Reports_And_Invoices: 'Reports & Invoices',
  Nav_Menu_Invoices: 'Invoices',
  Nav_Menu_Reports: 'Reports',
  Nav_Menu_Administrator: 'Administrator',
  Nav_Menu_Documents: 'Documents',
  Nav_Menu_Feedback: 'Feedback',
  Nav_Menu_News: 'News',
  Nav_Menu_Roles: 'Roles',
  Nav_Menu_Users: 'Users',
  Nav_Menu_NotificationTemplate: 'Notification Templates',
  Nav_Menu_Alerts: 'Alerts',
  Nav_Menu_UserGroups: 'User Groups',
  Nav_Menu_ObjectConfiguration: 'Object Configuration',
  Nav_Menu_Clients: 'Clients',
  Nav_Menu_InspectionLocations: 'Inspection Locations',

  Nav_Client_Quotes: 'Client Quotes',
  Nav_Equipments: 'Equipments',
  Nav_Actions: 'Actions',
  Nav_Menu_ClientQuotes: 'Client Quotes',
  Nav_Menu_Equipments: 'Equipments',
  Nav_Menu_Submission: 'SUBMISSION',
  Nav_Menu_Data_Schema: 'Data Schema',
  Nav_Menu_Time_Log_Calculation: 'Time Log Calculations',
  Nav_Menu_Subscriptions: 'Subscriptions',
  Nav_Menu_SampleSubmission: 'Submissions',
  Nav_Sample_Submission: 'Submissions',
  Nav_Menu_Target: 'Target',
  Nav_Menu_API: 'API',
  Nav_Menu_TranslationSets: 'Translation Sets',
  Nav_Menu_MyActions: 'My Actions',
  Nav_Menu_ActionCenter: 'Action Center',
  Nav_Menu_APILog: 'API Log',
  Nav_Menu_AssetManagement: 'Asset Management',
  Nav_Menu_Equipment: 'Equipment',
  Nav_Menu_EquipmentBrowse: 'Browse Equipments',
  Nav_Menu_Outbound: 'Outbound',
  Nav_Menu_Export: 'Export',
  Nav_Menu_Timestamp_Event: 'Timestamp Event',
  Nav_Menu_Quantity_Display_Group: 'Quantity Display Group',
  NewUser: 'New User',
  UserList: 'User List',
  Search: 'Search',
  All: 'All',
  News: 'News',

  Header_Home: 'Home',
  Header_SampleSearch: 'Samples',
  Header_ActionsSearch: 'Actions',
  Header_JobSearch: 'Jobs',
  Header_SampleDetails: 'Sample Details',
  Header_SampleInfo: 'Sample Info',
  Header_Results: 'Results',
  Header_JobInfo: 'Job Info',
  Header_InPrep: 'IN PREP',
  Header_InTransit: 'IN TRANSIT',
  Header_InAnalysis: 'IN ANALYSIS',
  Header_JobAge: 'JOB AGE',
  Header_SampleAge: 'SAMPLE AGE',
  Header_In: 'IN',
  Header_Out: 'OUT',
  Header_RecentNews: 'Recent News',
  Header_JobProgress: 'Job Progress',
  Header_SampleProgress: 'Sample Progress',
  Header_RoleSearch: 'Roles',
  Header_NewsSearch: 'News',
  Header_DocumentSearch: 'Documents',
  Header_Document: 'Document',
  Header_AddDocument: 'Add New Document',
  Header_EditDocument: 'Edit Document',
  Header_EditUserGroups: 'Edit User Groups',
  Header_UserGroups: 'User Groups',
  Header_UserGroup: 'User Group',
  Header_News: 'News',
  Header_General: 'General',
  Header_Access: 'Access',
  Header_KnowledgeCenter: 'Knowledge Center',
  Header_Documents: 'Documents',
  Header_AccountSetting: 'Account Settings',
  Header_Profile: 'Profile',
  Header_ReportSearch: 'Reports',
  Header_Analysis: 'Analysis',
  Header_Trending: 'Trending',
  Header_MyActions: 'My Actions',
  Header_InvoiceSearch: 'Invoices',
  Header_NotificationSetting: 'Notification Templates',
  Header_Triggers: 'Triggers',
  Header_Templates: 'Templates',
  Header_Template: 'Template',
  Header_Roles: 'Roles',
  Header_Permissions: 'Permissions',
  Header_NotificationsFor: 'Notifications for',
  Header_InvoiceInfo: 'Invoice Info',
  Header_Conditions: 'Conditions',
  Header_User: 'User',
  Header_UserSearch: 'Users',
  Header_TimeLogCalculation: 'Time Log Calculation',
  Header_UserEdit: 'User Edit',
  Header_UserCreate: 'User Create',
  Header_AttachedDocuments: 'Attached Documents',
  Header_InvoiceDetail: 'Invoice Detail',
  Header_FeedbackSearch: 'Feedback',
  Header_JobDetail: 'Job Detail',
  Header_SamplePreparationAndAnalyticalProcedures:
    'Sample Preparation And Analytical Procedures',
  Header_JobQuantityData: 'Quantity Data',
  Header_JobQuantityDataCalculation: 'Quantity Data Calculation',
  Header_JobTimeLogCalculation: 'Time Log Calculation',
  Header_JobStorageInformation: 'Job Storage Information',
  Header_Deliverables: 'Deliverables',
  Header_Alerts: 'Alerts',
  Header_Attachments: 'Attachments',
  Header_Result_History: 'Result History',
  Header_Status: 'Status',
  Header_Actions: 'Actions',
  Header_ClientQuotes: 'Client Quotes',
  Header_Equipments: 'Equipments',
  Header_AddClientQuotes: 'New Client Quotes',
  Header_EditClientQuotes: 'Edit Client Quotes',
  Header_DataSchema: 'Data Schema',
  Header_JobMessages: 'Job Messages',
  Header_Subscriptions: 'Subscriptions',
  Header_EditSchema: 'Edit Schema',
  Header_AddSubmission: 'New Sample Submission',
  Header_EditSubmission: 'Edit Sample Submission',
  Header_Submission: 'Submissions',
  Header_Targets: 'Targets',
  Header_EditTarget: 'Edit Target',
  Header_TranslationSets: 'Translation Sets',
  Header_EditTranslationSets: 'Edit Translation Sets',
  Header_EditSubscription: 'Outgoing Subscription',
  Header_Action_Column_New: 'To do',
  info_PermissionName_and_PermissionCode_CanNotBeEmpty: 'Permission Name and Permission Code Can Not Be Empty',
  Header_Action_Column_InProgress: 'In Progress',
  Header_Action_Column_Completed: 'Completed',
  Header_APILog: 'API Log',
  Header_Sample_Results_History: 'Sample Results History',
  Header_Lubrication: 'Lubrication',
  Header_CylinderOil: 'Cylinder Oil',
  Header_Assets: 'Assets',
  Header_Add_New_Widget: 'Add New Widget',
  Header_AssetManagement: 'Asset Management',
  Header_VesselInformation: 'Vessel Information',
  Header_ClientDetails: 'Client Details',
  Header_ClientSiteDetails: 'Client Site Details',
  Header_Equipment_Details: 'Equipment Details',
  Header_Recommendation: 'Recommendation',
  Header_Graphs: 'Graphs',
  Header_NoAssetName: 'No Asset Name',
  Header_SubmissionDetails: 'Submission Details',
  Header_SubmissionContacts: 'Submission Contacts',
  Header_SampleList: 'Sample List',
  Header_InvoiceDetailList: 'Invoice Detail List',
  Header_ComponentList: 'Component List',
  Header_EquipmentList: 'Equipment List',
  Header_ComponentDetails: 'Component Details',
  Header_UserGroupInformation: 'User Group Information',
  Header_ObjectConfiguration: 'Object Configuration',
  Header_GeneralInfo: 'General Info',
  Header_AddObjectConfiguration: 'Add Object Configuration',
  Header_EditObjectConfiguration: 'Edit Object Configuration',
  Header_AddTimeLogCalculation: 'Add Time Log Calculation',
  Header_EditTimeLogCalculation: 'Edit Time Log Calculation',
  Header_TimeLogCalculationInfo: 'Time Log Calculation Information',
  Header_Outbound: 'Outbound',
  Header_Export: 'Export',
  Header_Progress: 'Progress',
  Header_Client: 'Client',
  Header_Parcel: 'Parcel',
  Header_Clients: 'Clients',
  Header_InspectionLocations: 'Inspection Locations',
  Header_AddInspectionLocation: 'Add Inspection Location',
  Header_EditInspectionLocation: 'Edit Inspection Location',
  Header_InspectionLocationInfo: 'Inspection Location Info',
  Header_LaboratorySamplesAndResults: 'Laboratory Samples & Results',
  info_Permission_SaveSuccessfully: 'Permission Save Successfully',
  Header_QuantityDisplayGroups: 'Quantity Display Groups',
  Header_AddTimestampEvent: 'Add Timestamp Event',
  Header_EditTimestampEvent: 'Edit Timestamp Event',
  Header_TimestampEventInformation: 'Timestamp Event Information',
  Header_OnOff: 'On/Off',

  Breadcrumb_Home: 'Home',
  Breadcrumb_Users: 'Users',
  Breadcrumb_News: 'News',
  Breadcrumb_ClientQuotes: 'Client Quotes',
  Breadcrumb_Submissions: 'Submissions',
  Breadcrumb_DataSchemas: 'Data Schemas',
  Breadcrumb_TranslationSets: 'Translation Sets',
  Breadcrumb_Targets: 'Targets',
  Breadcrumb_Subscriptions: 'Subscriptions',
  Breadcrumb_AssetManagement: 'Asset Management',
  Breadcrumb_Document: 'Document',
  Breadcrumb_Documents: 'Documents',
  Breadcrumb_Invoices: 'Invoices',
  Breadcrumb_InvoiceDetails: 'Invoice Details',
  Breadcrumb_ObjectConfigurations: 'Object Configurations',
  Breadcrumb_InspectionLocations: 'Inspection Locations',
  Breadcrumb_TimeLogCalculation: 'Time Log Calculation',
  Breadcrumb_TimestampEvents: 'Timestamp Events',

  Label_True: 'True',
  Label_False: 'False',
  Label_City: 'City',
  Label_State: 'State',
  Label_Country: 'Country',
  Label_PostalCode: 'Postal Code',
  Label_LocationName: 'LocationName',
  Label_JobStatus: 'Job Status',
  Label_No_Widget: 'No Widget',
  Label_SampleName: 'Sample Name',
  Label_Analysis: 'Analysis',
  Label_SampleDescription: 'Sample Description',
  Label_SampleSource: 'Sample Source',
  Label_SamplingDateFrom: 'Sampling Date From',
  Label_SamplingDateTo: 'Sampling Date To',
  Label_DateCreatedFrom: 'Date Created From',
  Label_DateCreatedTo: 'Date Created To',
  Label_ExpirationDate: 'Expiration Date',
  Label_SampleStatus: 'Sample Status',
  Label_Location: 'Location',
  Label_BVLocations: 'BV Locations',
  Label_Client: 'Client',
  Label_Clients: 'Clients',
  Label_Projects: 'Projects',
  Label_Project: 'Project',
  Label_ProjectSites: 'Project/Sites',
  Label_Name: 'Name',
  Label_TargetDuration: 'Target Duration',
  Label_TimestampEvent1: 'Timestamp Event 1',
  Label_TimestampEvent2: 'Timestamp Event 2',
  Label_Job: 'Job',
  Label_ShipmentID: 'Shipment ID',
  Label_Sample: 'Sample',
  Label_AvgDays: 'Average Days',
  Label_Last3Months: 'Last 3 months',
  Label_Jobs: 'Jobs',
  Label_Samples: 'Samples',
  Label_TurnaroundTime: 'Turnaround Time',
  Label_Received: 'Received',
  Label_PrepStarted: 'Prep Started',
  Label_InAnalysis: 'In Analysis',
  Label_Due: 'Due',
  Label_DueDate: 'Due Date',
  Label_AssignedTo: 'Assigned To',
  Label_ActionTitle: 'Action Title',
  Label_ActionType: 'Action Type',
  Label_ActionNumber: 'Action Number',
  Label_ActionsFor: 'Actions For',
  Label_ActionStatus: 'Action Status',
  Label_Reported: 'Reported',
  Label_Invoiced: 'Invoiced',
  Label_RoleName: 'Role Name',
  Label_Active: 'Active',
  Label_InActive: 'InActive',
  Label_Display: 'Display',
  Label_Title: 'Title',
  Label_Zone: 'Zone',
  Label_DocumentType: 'Document Type',
  Label_Roles: 'Roles',
  Label_Locations: 'Locations',
  Label_Division: 'Division',
  Label_Variant: 'Variant',
  Label_TestItems: 'Test Items',
  Label_AssignedLocations: 'Assigned Locations',
  Label_AssignedDivisions: 'Assigned Divisions',
  Label_SourceSystems: 'Source Systems',
  Label_Description: 'Description',
  Label_Summary: 'Summary',
  Label_Details: 'Details',
  Label_User: 'User',
  Label_ImpersonateUser: 'Impersonate User',
  Label_Sort: 'Sort',
  Label_Direction: 'Direction',
  Label_FromAToZ: 'From A to Z',
  Label_FromZToA: 'From Z to A',
  Label_ReportName: 'Report Name',
  Label_JobNumber: 'Job Number',
  Label_DateGeneratedFrom: 'Date Generated From',
  Label_DateGeneratedTo: 'Date Generated To',
  Label_JobName: 'Job Name',
  Label_ReportType: 'Report Type',
  Label_Template: 'Template',
  Label_Subject: 'Subject',
  Label_Body: 'Body',
  Label_HTMLBody: 'HTML Body',
  Label_NotificationType: 'Notification Type',
  Label_EmailPlaceHolder: 'Email Placeholders',
  Label_Message: 'Message',
  Label_Permissions: 'Permissions',
  Label_Comments: 'Comments',
  Label_CreatedBy: 'Created By',
  Label_CreatedOn: 'Created On',

  Label_Rating: 'Rating',
  Label_Feedback: 'Feedback',
  Label_NewPassword: 'New Password',
  Label_ConfirmNewPassword: 'Confirm New Password',
  Label_CustomerAlert: 'Customer Alert',
  Label_Sites: 'Site(s)',
  Label_From: 'From',
  Label_To: 'To',
  Label_FirstName: 'First Name',
  Label_LastName: 'Last Name',
  Label_Email: 'Email',
  Label_DateFormat: 'Date Format',
  Label_DateTimeFormat: 'Date Time Format',
  Label_Timezone: 'Timezone',
  Label_Role: 'Role',
  Label_SearchUser: 'Search User',
  Label_Invoice: 'Invoice',
  Label_InvoiceNumber: 'Invoice Number',
  Label_InvoiceType: 'Type',
  Label_InvoiceDateFrom: 'Invoice Date From',
  Label_InvoiceDateTo: 'Invoice Date To',
  Label_CC: 'CC',
  Label_BCC: 'BCC',
  Label_DateReceivedFrom: 'Date Received From',
  Label_DateReceivedTo: 'Date Received To',
  Label_DateCompletedFrom: 'Date Completed From',
  Label_DateCompletedTo: 'Date Completed To',
  Label_RecentJobs: 'Recent Jobs',
  Label_LinkedJobs: 'Linked Jobs',
  Label_RecentSamples: 'Recent Samples',
  Label_Stretch: 'Stretch',
  Label_Compact: 'Compact',
  Label_ForgotPassword: 'Forgot Password',
  Label_SampleByStatus: 'Sample By Status',
  Label_Last30days: 'Last 30 days',
  Label_YTD: 'YTD',
  Label_SpecResult: 'Spec Result',
  Label_QuoteNumber: 'Quote Number',
  Label_AttachedFile: 'Attached File',
  Label_Supplier: 'Supplier',
  Label_QuotationInfo: 'Quotation Info',
  Label_Attachments: 'Attachments',
  Label_Laboratory: 'Laboratory',
  Label_ContractNumber: 'Contract Number',
  Label_StartDate: 'Start Date',
  Label_EndDate: 'End Date',
  Label_DateFrom: 'Date From',
  Label_DateTo: 'Date To',
  Label_SchemaName: 'Schema Name',
  Label_SchemaType: 'Schema Type',
  Label_SourceSystem: 'Source System',
  Label_DataSourceType: 'Data Source Type',
  Label_ContentType: 'Content Type',
  Label_SampleLocation: 'Sample Location',
  Label_SubscriptionName: 'Subscription Name',
  Label_Target: 'Target',
  Label_TargetDetail: 'Target Detail',
  Label_EventTrigger: 'Event Trigger',
  Label_General: 'General',
  Label_TranslationSet: 'Translation Set',
  Label_ClientOrderNumber: 'Client Order Number',
  Label_ClientQuotation: 'Client Quotation',
  Label_DispatchDate: 'Dispatch Date',
  Label_TransportCompany: 'Transport Company',
  Label_ConsignmentNumber: 'Consignment Number',
  Label_DispatchedIn: 'Dispatched In',
  Label_ProjectNumber: 'Project Number',
  Label_SampleIdentityNumber: 'Sample Identity Number',
  Label_HazardsMaterial: 'Hazards Material',
  Label_HazardOther: 'Hazard Other',
  Label_TurnAroundTime: 'Turnaround Time',
  Label_SamplePreperation: 'Sample Preparation',
  Label_SampleMatrix: 'Sample Matrix',
  Label_CountryOfOrigin: 'Country Of Origin',
  Label_Priority: 'Priority',
  Label_Trending: 'Trending',
  Label_Filters: 'Filters',
  Label_ChartTypes: 'Chart Types',
  Label_Custom: 'Custom',
  Label_TargetName: 'Target Name',
  Label_MessageMappping: 'Message Mapping',
  Label_ItemSetting: 'Item Setting',
  Label_Output: 'Output',
  Label_Markers: 'Markers',
  Label_NewRow: 'New Row',
  Label_Label: 'Label',
  Label_Value: 'Value',
  Label_DataType: 'Data Type',
  Label_Type: 'Type',
  Label_Operator: 'Operator',
  Label_Required: 'Required',
  Label_Translatable: 'Translatable',
  Label_TriggerTemplate: 'Trigger Template',
  Label_MatchingCondition: 'Matching Condition',
  Label_TranslationKey: 'Translation Key',
  Label_DataSchema: 'Data Schema',
  Label_TranslationSetName: 'Translation Set Name',
  Label_Field: 'Field',
  Label_Table: 'Table',
  Label_Condition: 'Condition',
  Label_TemplateCondition: 'Template Condition',
  Label_Method: 'Method',
  Label_Path: 'Path',
  Label_ProcessStatus: 'Process Status',
  Label_Error: 'Error',
  Label_DispatchStatus: 'Dispatch Status',
  Label_Response: 'Response',
  Label_Host: 'Host',
  Label_APIPath: 'API Path',
  Label_Encoding: 'Encoding',
  Label_AuthenticationSchema: 'Authentication Schema',
  Label_HttpMethod: 'Http Method',
  Label_BearerToken: 'Bearer Token',
  Label_WebAPI: 'WebAPI',
  Label_MessageType: 'Message Type',
  Label_Headers: 'Headers',
  Label_ShowHideTree: 'Show/Hide Tree',
  Label_SerialNo: 'SerialNo',
  Label_EquipmentName: 'Equipment Name',
  Label_EquipmentNo: 'Equipment No',
  Label_Site: 'Site',
  Label_Model: 'Model',
  Label_Manufacturer: 'Manufacturer',
  Label_ClientInformation: 'Client Information',
  Label_QuickFilter: 'Quick Filter',
  Label_DateCreated: 'Date Created',
  Label_DateReceived: 'Date Received',
  Label_Default: 'Default',
  Label_RowsPerPage: 'Rows per page:',
  Label_ShowHideFilers: 'Show/Hide Filters',
  Label_NewActionFor: 'New action for',
  Label_ShowingNEquipments: 'Showing {{n}} Equipments(s)',
  Label_ShowHideOutput: 'Show/Hide Output',
  Label_ReportedOn: 'Reported On',
  Label_ReportReprint: 'Report Reprint',
  Label_Approved: 'Approved',
  Label_OrderNumber: 'Order Number',
  Label_LocationType: 'Location Type',
  Label_ExportData: 'Export Data',
  Label_GroupName: 'Group Name',
  Label_IsTemplate: 'Is Template',
  Label_Recipients: 'Recipients',
  Label_EnterEmail: 'Enter Email',
  Label_UserType: 'User Type',
  Label_ObjectType: 'Object Type',
  Label_Configs: 'Configs',
  Label_OutboundID: 'Outbound ID',
  Label_Status: 'Status',
  Label_ProcessedOn: 'Processed On',
  Label_SourceSystemResponse: 'SourceSystem Response',
  Label_OutboundDetail: 'Outbound Detail',
  Label_JobDescription: 'Job Description',
  Label_ProductGrade: 'Product Grade',
  Label_SampleNumber: 'Sample Number',
  Label_NoTransformationNeeded: 'No Transformation Needed',
  Label_ClientName: 'Client Name',
  Label_SpecialInstruction: 'Special Instruction',
  Label_Latitude: 'Latitude',
  Label_Longitude: 'Longitude',
  Label_DefaultTimeZone: 'Default Time Zone',
  Label_clientName: 'Client Name',
  Label_active: 'Active',
  Label_client: 'Client',
  Label_recordId: 'Record ID',
  Label_objectType: 'Object Type',
  Label_locationName: 'Location Name',
  Label_type: 'Type',
  Label_country: 'Country',
  Label_city: 'City',
  Label_state: 'State',
  Label_postalCode: 'Postal Code',
  Label_UserGroupName: 'Group Name',
  Label_TimeStamp: 'Timestamp Event',
  Label_Tag: 'Tag',
  Label_EventName: 'Event Name',
  Label_Category: 'Category',
  Label_SentToCustomerPortal: 'Sent To Customer Portal',
  Label_CaptureEstimatedDate: 'Capture Estimated Date',
  Label_Permission_Name_is_required: 'Permission Name is required',
  Label_Permission_Code_is_required: 'Permission Code is required',
  Label_Title_is_required: 'Title is required',
  Label_Max_length_n: 'Max length is {{n}}',
  Label_Document_Type_is_required: 'Document Type is required',
  Label_Description_is_required: 'Description is required',
  Label_File_is_required: 'File is required',
  Label_Load_document_failed: 'Load document failed',
  Label_SubmissionID: 'Submission ID',
  Label_CreatedOnTo: 'Created On To',
  Label_CreatedOnFrom: 'Created On From',

  Button_Save: 'Save',
  Button_Save_As_Template: 'Save As Template',
  Button_SaveFilter: 'Save Filter',
  Button_SavedFilter: 'Saved Filter',
  Button_Submit: 'Submit',
  Button_Update: 'Update',
  Button_Test: 'Test',
  Btn_ShowFilter: 'Show Filter',
  Btn_HideFilter: 'Hide Filter',
  Button_Back: 'Back',
  Button_Delete: 'Delete',
  Btn_Apply: 'Apply',
  Btn_Reset: 'Reset',
  Btn_ClearFilters: 'Clear Filters',
  Btn_ManageDisplay: 'Manage Display',
  Btn_StopImpersonating: 'Stop Impersonating',
  Btn_Logout: 'Log Out',
  Btn_Add: 'Add',
  Btn_Cancel: 'Cancel',
  Btn_Yes: 'Yes',
  Btn_No: 'No',
  Btn_Upload: 'Upload',
  Btn_NewDocument: 'Document',
  Btn_AddNews: 'News',
  Btn_Submit: 'Submit',
  Btn_Settings: 'Settings',
  Btn_Profile: 'Profile',
  Btn_ManageFilters: 'Manage Filters',
  Btn_Clear: 'Clear',
  Btn_Save: 'Save',
  Btn_PlaceHolders: 'PlaceHolders',
  Btn_AddRole: 'Role',
  Btn_AddActions: 'New Action',
  Btn_EditNotifications: 'Notifications',
  Btn_Export: 'Export',
  Btn_Close: 'Close',
  Btn_Preview: 'Preview',
  Btn_ChangeMyPassword: 'Change my password',
  Btn_ManageDate: 'Manage Date',
  Btn_ViewUser: 'View User',
  Btn_Next: 'Next',
  Btn_Previous: 'Previous',
  Btn_Complete: 'Complete',
  Btn_LinkMore: '... more',
  Btn_Refresh: 'Refresh',
  Btn_SaveOrder: 'Save Order',
  Btn_Create_Request: 'Create Request',
  Btn_AddNew: 'Add New',
  Btn_Submission: 'Submission',
  Btn_Start: 'Start',
  Btn_ReOpen: 'Re-open',
  Btn_Comment: 'Comment',
  Btn_ShowMoreComments: 'More',
  Btn_StartAction: 'Start Action',
  Btn_NewAction: 'New Action',
  Btn_Load5MoreLastCompletedActions: 'Load 5 more last completed actions',
  Btn_HideOutput: 'Hide Output',
  Btn_ShowOutput: 'Show Output',
  Btn_MapAndList: 'Map & List',
  Btn_MapOnly: 'Map Only',
  Btn_ListOnly: 'List Only',
  Btn_Create: 'Create',
  Btn_Beautify: 'Beautify',
  Btn_Minify: 'Minify',
  Btn_Generate: 'Generate',
  Btn_ShowAll: 'Show All',
  Column_Action: 'Action',
  column_DocumentTypeName: 'Document Type',
  Column_JobNumber: 'Job Number',
  Column_Title: 'Title',
  Column_Filename: 'Filename',
  Column_GeneratedOn: 'Generated On',
  Column_Date: 'Date',
  Column_Headline: 'Headline',
  Column_Select: 'Select',
  Column_FirstName: 'First Name',
  Column_LastName: 'Last Name',
  Column_EmailAddress: 'Email Address',
  Column_UpdatedOn: 'Updated On',
  Column_Category: 'Category',
  Column_Severity: 'Severity',
  Column_Sample_Date: 'Sample Date',
  Column_Component_Age: 'Component Age',
  Column_Lube_Age: 'Lube Age',
  Column_Filter_Changed: 'Filter Changed',
  Column_Product: 'Product',
  Column_Website: 'Website',
  Column_Sample_Number: 'Sample_Number',
  Column_ProductName: 'Product',
  Column_ParcelName: 'Parcel',
  Column_Relationship: 'Relation',
  Column_IR: 'IR',
  Column_CR: 'CR',
  Column_NI: 'NI',
  Column_AL: 'AL',
  Column_LD: 'LD',
  Column_CO: 'CO',
  Column_TI: 'TI',
  Column_SI1: 'SI',
  Column_TT: 'TT',
  Column_VA: 'VA',
  Column_SI2: 'SI',
  Column_ModelID: 'Model',
  Column_AssetNumber: 'Asset Number',
  Column_Component: 'Component',
  Column_Manufacturer: 'Manufacturer',
  Column_Model: 'Model',
  Column_Serial: 'Serial',
  Column_Name: 'Name',
  Column_TimeLogCalcDisplayName: 'Name',
  Column_TimeLogEventName1: 'Start',
  Column_TimeLogEventName2: 'End',
  Column_Duration: 'Result (Duration)',
  Column_AssetNo: 'Asset No',
  Column_SerialNo: 'Serial No',
  Column_CreatedOn: 'Created On',
  Column_Client: 'Client',
  Column_ContractNumber: 'Contract Number',
  Column_JobName: 'Job Name',
  Column_JobStatusName: 'Job Status Name',
  Column_JobDescription: 'Job Description',
  Column_ClientReference: 'Client Reference',
  Column_JobStartDate: 'Job Start Date',
  Column_JobCoordinator: 'Job Coordinator',
  Column_JobDateReceived: 'Job Date Received',
  Column_InvoiceNumber: 'InvoiceNumber',
  Column_Type: 'Type',
  Column_Amount: 'Amount',
  Column_InvoiceDate: 'Invoice Date',
  Column_PaymentDueDate: 'Payment Due Date',
  'Column_Sample Details': 'Sample Details',
  Column_LineItemDescription: 'Description',
  Column_Unit: 'Unit',
  Column_Currency: 'Currency',
  Column_Quantity: 'Quantity',
  'Column_Unit Price': 'Unit Price',
  Column_VAT: 'VAT',
  Column_TotalWithTax: 'Total (with VAT)',
  Column_TotalWithoutTax: 'Total (without VAT)',
  Column_JobCompletionDate: 'Job Completion Date',
  Column_StatusName: 'Status Name',
  Column_DateReceived: 'Date Received',
  Column_AnalysticalStartDate: 'Analytical Start Date',
  Column_AnalyticalStartDate: 'Analytical Start Date',
  Column_FirstDateFinished: 'First Date Finished',
  Column_SampleCount: 'Sample Count',
  Column_FirstSampleNumber: 'First Sample Number',
  Column_LastSampleNumber: 'Last Sample Number',
  Column_SampleTypeName: 'Sample Type Name',
  Column_ClientNames: 'Client Names',
  Column_ClientOrderNo: 'Client Order No',
  Column_HasCOA: 'Has COA',
  Column_SampleName: 'Sample Name',
  Column_Selection: 'Selection',
  Column_ClientSampleNo: 'Client Sample No',
  Column_SampleSequence: 'Sample Sequence',
  Column_LocationName: 'Location Name',
  Column_ReceivedOn: 'Received On',
  Column_SamplingDate: 'Sampling Date',
  Column_BatchLotRef: 'Batch Lot Ref',
  Column_AnalysisStartDate: 'Analysis Start Date',
  Column_AnalysisCompleteDate: 'Analysis Complete Date',
  Column_ClientSampleRef: 'Client Sample Ref',
  Column_FilterAge: 'Filter Age',
  Column_TimeSinceFilterChange: 'Time Since Filter Change',
  Column_OwnerName: 'Owner Name',
  Column_ActionTypeName: 'Action Type Name',
  Column_DueDate: 'Due Date',
  Column_BelongsTo: 'Belongs To',
  Column_ObjectName: 'Object Name',
  Column_CreatedByName: 'Created By Name',
  Column_AcceptedOn: 'Accepted On',
  Column_AcceptedByName: 'Accepted By Name',
  Column_CompletedOn: 'Completed On',
  Column_CompletedByName: 'Completed By Name',
  Column_ParameterName: 'Parameter Name',
  Column_SpecName: 'Spec Name',
  Column_ResultValue: 'Result Value',
  Column_ResultUniName: 'Result Unit Name',
  Column_SiteName: 'Site Name',
  Column_SampleDescription: 'Sample Description',
  Column_SubmissionID: 'Submission ID',
  Column_SubmittedBy: 'Submitted By',
  Column_SubmittedOn: 'Submitted On',
  Column_InboundID: 'Inbound ID',
  Column_ProcessedOn: 'Processed On',
  Column_ExtSystemCode: 'Ext System Code',
  Column_Object: 'Object',
  Column_Record: 'Record',
  Column_Comment: 'Comment',
  Column_Rating: 'Rating',
  'Column_Created On': 'Created On',
  'Column_Created By': 'Created By',
  Column_Username: 'Username',
  Column_ReportType: 'Report Type',
  Column_Project: 'Project',
  Column_Brand: 'Brand',
  Column_Description: 'Description',
  Column_TimeLogCalculationDescription: 'Description',
  Column_Source: 'Source',
  Column_Supplier: 'Supplier',
  Column_SampleLocation: 'Sample Location',
  Column_ProcedureCode: 'Procedure Code',
  Column_Samples: 'Samples',
  Column_CodeDescription: 'Code Description',
  Column_Result: 'Result (duration)',
  Label_Start: 'Start',
  Label_End: 'End',
  Parcel: 'Parcel',
  Column_Status: 'Status',
  Column_Lab: 'Lab',
  Column_ClientOrderNumber: 'Client Order Number',
  Column_AssetName: 'Asset Name',
  Column_ClientName: 'Client Name',
  Column_ManufacturerName: 'Manufacturer Name',
  Column_SampledDate: 'Sampled Date',
  Column_DocumentID: 'Document ID',
  Column_CreatedBy: 'Created By',
  InEx: 'In/Ex',
  Column_Calculation: 'Calculation',
  Column_Dry_Weight: 'Dry Weight (DMT)',
  Column_Moisture: 'Moisture',
  Column_Wet_Weight: 'Wet Weight (WMT)',
  Column_JobParcelID: 'Parcel',
  Column_VefFactor: 'VEF Factor',
  Column_UserGroupName: 'Name',
  Column_RoleName: 'Role Name',
  Column_UserType: 'User Type',
  Column_LastLogin: 'Last Login',
  Column_Salutation: 'Salutation',
  Column_ObjectConfigurationID: 'ID',
  Column_ObjectType: 'Object Type',
  Column_RecordID: 'Record ID',
  Column_LastUpdatedOn: 'Updated On',
  Column_LastUpdatedBy: 'Updated By',
  Column_OutboundID: 'Outbound ID',
  Column_SourceSystemResponse: 'SourceSystem Response',
  Column_SampleNumber: 'Sample Number',
  Column_ExpectedDate: 'Expected Date',
  Column_TargetDate: 'Target Date',
  Column_Report: 'Report',
  Column_PostalCode: 'Postal Code',
  Column_ResultUnit: 'Result Unit',

  Toast_UpdatedSuccessfully: 'Updated Successfully',
  Toast_Add_new_document_successfully: 'Add new document successfully',
  Toast_Add_document_failed: 'Add document failed',
  Toast_Create_document_failed: 'Create document failed',
  Toast_UpdateFailed: 'Update Failed',
  Toast_CreatedSuccessfully: 'Created Successfully',
  Toast_SelectedSuccessfully: 'Selected Successfully',
  Toast_SavedSuccessfully: 'Save sucessfully!',
  Toast_ColumnDisplayUpdatedSuccessfully: 'Column Display updated successfully!',
  Toast_ColumnDisplayUpdateFailed: 'Column Display update failed!',
  Toast_ColumnsCannotBeRetrieved: 'Columns cannot be retrieved!',
  Toast_UnableToSaveFilter: 'Unable to save filter preset!',
  Toast_FilterDisplayUpdatedSuccessfully: 'Filter Display updated successfully!',
  Toast_FilterDisplayUpdateFailed: 'Filter Display update failed!',
  Toast_ConfiguredFieldsCannotBeRetrieved: 'Configured fields cannot be retrieved!',
  Toast_ActionStatusUpdatedSuccessfully: 'Action status updated successfully!',
  Toast_FilesUploadedSuccessfully: 'File(s) uploaded successfully',
  Toast_FileRemovedSuccessfully: 'File removed successfully',
  Toast_FileDownloadedSuccessfully: 'File downloaded successfully!',
  Toast_ActionAddedSuccessfully: 'Action added successfully!',
  Toast_UserCreatedSuccessfully: 'An email will be sent out shortly to your provided email address, please check that in your email Inbox including spam/junk folders.',

  Error_Message_NoDataFound: 'No Data Found',
  Error_LoadingError: 'Loading error',
  Error_Message_NoColumnsFound: 'No columns found!',
  Error_NoItemsFound: 'No items found!',
  Error_NoPresetFound: 'No preset found!',
  Info_RecoveryPasswordEmailHasBeenSent: 'Recovery password email has been sent',
  Error_DateRangeCannotExceed3Months: 'Date range cannot exceed 3 months, please try a different range.',
  Confirm_Message_Delete: 'Are you sure you want to delete this record?',
  Confirm_Message_DeleteTemplate:
    'Are you sure you want to delete this template?',
  Confirm_Title_ArchiveDocument: 'Archive document: ',
  Confirm_Message_ArchiveDocument:
    'Are you sure you want to archive this document?',
  Confirm_Title_UnarchiveDocument: 'Unarchive document: ',
  Confirm_Message_UnarchiveDocument:
    'Are you sure you want to unarchive this document?',
  Confirm_Title_ConfirmDelete: 'Confirm Delete',
  Info_Message_ExportSuccess: 'Export data successfully',
  Error_ActionTypeRequired: 'Action Type is required',
  Error_AssignedToRequired: 'Assigned To is required',
  Error_TitleRequired: 'Title is required',
  Error_DueDateRequired: 'Due Date is required',
  Error_LaboratoryIsRequired: 'Laboratory Is Required',
  Error_DispatchDateIsRequired: 'Dispatch Date Is Required',
  Error_TurnAroundTimeIsRequired: 'Turn Around Time Is Required',
  Error_HazardsMaterialIsRequired: 'Hazards Material Is Required',
  Error_DispatchedInIsRequired: 'Dispatched In Is Required',
  Error_Message_ExportSuccess: 'Error to export data',
  Info_Message_SaveSuccessfully: 'Save Successfully',
  Error_Message_SaveError: 'Save Failed',
  Info_Message_DownloadSuccess: 'Download Successfully',
  Error_Message_DownloadError: 'Download Failed',
  Info_Message_DeleteSuccess: 'Delete Successfully',
  Error_Message_DeleteError: 'Delete Failed',
  Error_Message_ChangePasswordError: 'Change Password Failed',
  Info_Message_UpdateUserSuccess: 'Update User Successfully',
  Error_Message_UpdateUserError: 'Update User Failed',
  Error_Message_LoadUserError: 'Load User Failed',
  Error_ClientIsRequired: 'Client is Required',
  Error_LocationIsRequired: 'Location is Required',
  Error_SourceSystemIsRequired: 'Source System is Required',
  Error_DataSourceTypeIsRequired: 'Data Source Type is Required',
  Error_ContentTypeIsRequired: 'Content Type is Required',
  Error_ObjectTypeIsRequired: 'Object Type is Required',
  Info_Message_DownloadReportsSuccess: 'Reports downloaded successfully!',
  Info_Message_DownloadInvoicesSuccess: 'Invoices downloaded successfully!',
  Error_Message_SomethingWentWrong: 'Something went wrong!',
  Error_TemplateNameIsRequired: 'Template Name is Required',
  Error_LoadingDataError: 'Loading Data Error',
  Created_Submission_Successfully: 'Created Submission Successfully',
  Error_TargetNameIsRequired: 'Target Name is required',
  Error_DivisionIsRequired: 'Division is required',
  Toast_Quantity_Display_Group_Deleted: 'Quantity Display Group Deleted',
  Info_Message_MessageMappingModes: 'Message Mapping Modes',
  Error_TranslationSetNameIsRequired: 'Translation Set Name is Required',
  Error_BelongsToIsRequired: 'BelongsTo is Required',
  Error_RecordIDIsRequired: 'RecordID is Required',
  Error_EventTriggerIsRequired: 'Event Trigger is required',
  Error_TemplateIsRequired: 'Template is required',
  Error_TargetDetailIsRequired: 'Target Detail is required',
  Error_WebApiIsRequired: 'Web Api is required',
  Error_BearerTokenIsRequired: 'Bearer Token is required',
  Error_PostIsRequired: 'Post is required',
  Error_HostNameIsRequired: 'Host Name is required',
  Error_APIPathIsRequired: 'API Path is required',
  Error_EncodingIsRequired: 'Encoding is required',
  Error_NameIsRequired: 'Name is required',
  Error_DateFromRequired: 'Date From is required',
  Error_DateToRequired: 'Date To is required',
  Error_TypeIsRequired: 'Type is required',
  Error_CountryIsRequired: 'Country is required',
  Error_TimeStampEvent1IsRequired: 'Time Stamp Event 1 Is Required',
  Error_TimeStampEvent2IsRequired: 'Time Stamp Event 2 Is Required',
  Error_TargetDurationIsRequired: 'Target Duration Is Required',
  Error_TargetDurationMustBeInDecimalForm: 'Target Duration must be in decimal form',

  Message_NoComments: 'No Comments',
  PlaceHolder_Comments: 'Your comment here...',

  Tooltip_Download: 'Download',
  Tooltip_TimestampEvent: 'Timestamp Events',
  Tooltip_Archive: 'Archive',
  Tooltip_Unarchive: 'Unarchive',
  Tooltip_Default: 'Default',
  Tooltip_DocumentSearch: 'Access BV | Documents',
  Tooltip_KnowledgeCenter: 'Access BV | Knowledge Center',
  Tooltip_AccountSetting: 'Access BV | Account Setting',
  Tooltip_ReportSearch: 'Access BV | Reports',
  Tooltip_NotificationSetting: 'Access BV | Notification Setting',
  Tooltip_Favorite: 'Favorite',
  Tooltip_Unfavorite: 'Unfavorite',
  Tooltip_Export_To_CSV: 'Export to CSV',
  Tooltip_ClientQuotes: 'Access BV | Client Quotes',
  Tooltip_Equipments: 'Access BV | Equipments',
  Tooltip_AddClientQuotes: 'New Client Quotes',
  Tooltip_EditClientQuotes: 'Access BV | Edit Client Quotes',
  Tooltip_DataSchema: 'Access BV | Data Schema',
  Tooltip_AddSubmission: 'New Submission',
  Tooltip_EditSubmission: 'Edit Submission',
  Tooltip_EditDataSchema: 'Access BV | Edit Data Schema',
  Tooltip_TranslationSets: 'Access BV |Translation Sets',
  Tooltip_Translations: 'Translations',
  Tooltip_AddTranslationSets: 'Access BV | Add Translation Sets',
  Tooltip_EditTranslationSets: 'Access BV | Edit Translation Sets',
  Tooltip_EditTarget: 'Edit Target',
  Tooltip_Subscriptions: 'Access BV | Subscriptions',
  Tooltip_EditSubscription: 'Access BV | Edit Subscription',
  Tooltip_Targets: 'Targets',
  Tooltip_CreateAction: 'Create Action',
  Tooltip_APILog: 'Access BV | APILog',
  Tooltip_Destination: 'Destination',
  Tooltip_Setting: 'Setting',
  Tooltip_Authentication: 'Authentication',
  Tooltip_General: 'General',
  Tooltip_AssetManagement: 'Access BV | Asset Management',
  Tooltip_EditLayout: 'Edit Layout',
  Tooltip_UserGroups: 'Access BV | User Groups',
  Tooltip_AddObjectConfiguration: 'Access BV | Add Object Configuration',
  Tooltip_EditObjectConfiguration: 'Access BV | Edit Object Configuration',
  Tooltip_Outbound: 'Access BV | Outbound',
  Tooltip_Client: 'Access BV | Client',
  Tooltip_AddInspectionLocation: 'Access BV | Add Inspection Location',
  Tooltip_EditInspectionLocation: 'Access BV | Edit Inspection Location',
  Tooltip_AddTimestampEvent: 'Access BV | Add Timestamp Event',
  Tooltip_EditTimestampEvent: 'Access BV | Edit Timestamp Event',

  Tab_AllDocuments: 'Documents and instruction manuals',
  Tab_UserDocuments: 'User Documents',
  Tab_LatestNews: 'Latest News',
  Tab_NewsArchived: 'News Archived',
  Tab_Laboratory_Reports: 'Laboratory Reports',
  Tab_Data: 'Data',
  Tab_Invoices: 'Invoices',
  Tab_Other: 'Other',
  Tab_Documents: 'Documents',
  Tab_News: 'News',
  Tab_Design: 'Design',
  Tab_Code: 'Code',
  Tab_Decoration: 'Decoration',
  Tab_Conditions: 'Conditions',
  Tab_Translation: 'Translation',
  Tab_Title_AppName: 'CTD AccessBV',
  Tab_General: 'General',
  Tab_Profile: 'Profile',
  Tab_Notifications: 'Notifications',
  Tab_Inspection_Reports: 'Inspection Reports',
  Tab_Nominations: 'Nominations',
  Tab_Photos: 'Photos',

  Placeholder_Selectanoption: 'Select an option',

  Text_AddClientQuotes: 'New Client Quotes',
  Text_EditClientQuotes: 'Edit Client Quotes',
  Text_Trigger: 'Trigger',
  Text_EditDataSchemas: 'Edit Data Schemas',
  Text_AddSubmissions: 'Add Submissions',
  Text_EditSubmissions: 'Edit Submissions',
  Text_AddTranslationSets: 'Add Translation Sets',
  Text_EditTranslationSets: 'Edit Translation Sets',
  Text_EditTarget: 'Edit Target',
  Text_EditSubscriptions: 'Edit Subscriptions',
  Text_AddInspectionLocation: 'Add Inspection Location',
  Text_EditInspectionLocation: 'Edit Inspection Location',
  Text_AddTimestampEvent: 'Add Timestamp Event',
  Text_EditTimestampEvent: 'Edit Timestamp Event',
  Text_QuantityDisplayListExported: 'QuantityDisplay_List_Exported',

  Axis_TurnaroundTime: 'Turnaround Time (days)',
  Label_Import_CSV: 'Import CSV',
  Label_belongsTo: 'Belongs To',
  Label_dateCreatedFrom: 'Date Created From',
  Label_dateCreatedTo: 'Date Created To',
  Label_View_Details: 'View Detail',
  Label_View_Feedback: 'Feedback',
  Label_reportsFor: 'Reports For',
  Label_ReportsFor: 'Reports For',
  Label_Add_Submission_Contacts: 'Submission Contacts',
  Label_Add_Sample_List: 'Sample List',
  Label_Add_Storage: 'Storage',
  Label_Template_Name: 'Template Name',
  Label_BelongsTo: 'Belongs To',
  Label_RecordID: 'Record ID',
  Label_Add: 'Add',
  Label_Save: 'Save',
  Label_ViewMore: 'View More',
  Label_ViewLess: 'View Less',
  Label_Inbound: 'Inbound',
  Label_Outbound: 'Outbound',
  Label_No_Records_Found: 'No records found!',
  Label_No_Invoice_Found: 'No invoice found',
  Dialog_Title_FeedbackHeader: 'Feedback for',
  Dialog_Title_NewActionForJob: 'New Action For Job {{name}}',
  Dialog_Title_NewActionForSample: 'New Action For Sample {{name}}',
  Dialog_Title_NewActionForInvoice: 'New Action For Invoice {{name}}',
  Dialog_Title_NewActionForReport: 'New Action For Report {{name}}',

  Title_NewSubscription: 'New Subscription',
  Title_NewTarget: 'New Target',
  Title_NewHeader: 'New Header',
  Title_Showing_X_Records: 'Showing {{length}} record(s)',
  Title_General: 'General',
  Title_Access: 'Access',
  Title_NewTranslationSets: 'New Translation Sets',
  Title_NewUserGroup: 'New User Group',
  Title_AddTimeLogCalculation: 'Add Time Log Calculation',
  Title_EditTimeLogCalculation: 'Edit Time Log Calculation',

  Status_New: 'New',
  Status_Created: 'Created',
  Status_ReadyForAnalysis: 'Ready For Analysis',
  Status_AnalysisStarted: 'Analysis Started',
  Status_QCChecked: 'QC Checked',
  Status_JobFinished: 'Job Finished',
  Status_InAnalysis: 'In Analysis',
  Status_InPrep: 'In Prep',
  Status_InProgress: 'In Progress',
  Status_Scheduled: 'Scheduled',
  Status_Completed: 'Completed',
  Status_Sampled: 'Sampled',
  Status_Started: 'Started',
  'Status_Not Sampled': 'Not Sampled',
  Status_Complete: 'Complete',
  'Status_In Progress': 'In Progress',
  Recent_News: 'Recent News',
  In_Prep_Count: 'IN PREP',
  In_Transit_Count: 'IN TRANSIT',
  In_Analysis_Count: 'IN ANALYSIS',
  Job_Age_Count: 'JOB AGE',
  In_Stats: 'IN',
  Out_Stats: 'OUT',
  Site_Map: 'Site(s)',
  Turnaround_Time: 'Turnaround Time',
  Recent_Jobs: 'Recent Jobs',
  Recent_News_Description: 'Recent news description',
  Site_Map_Description: 'Site map description',
  In_Prep_Count_Description: 'IN PREP description',
  In_Transit_Count_Description: 'IN TRANSIT description',
  In_Analysis_Count_Description: 'IN ANALYSIS description',
  Job_Age_Count_Description: 'JOB AGE description',
  In_Stats_Description: 'IN Stats Description',
  Out_Stats_Description: 'OUT Stats description',
  Recent_Jobs_Description: 'Recent Jobs description',
  Turnaround_Time_Description: 'This will show job turn around time',
  DocumentTypeID: 'Document Type ID',
  CreatedOn: 'Created On',
  CreatedBy: 'Created By',
  document: 'document',
  Active: 'Active',
  No_items_found: 'No items found',
  LastUpdatedOn: 'Last Updated On',
  LastUpdatedBy: 'Last Updated By',
  new: 'new',
  feedback: 'feedback',
  No_Feedback_Found: 'No Feedback Found',
  Note_displayed_and_downloaded: 'Files can be displayed and downloaded in knowledge center section.',
  File: 'File',
  browse: 'browse',
  thorough_your_machine: 'thorough your machine',
  Label_Select_File: 'Select file',
  Label_Select_Files: 'Select files',
  Label_Drop_File: 'Drop file',
  Label_Drop_Files: 'Drop files',
  InvoiceDetails: 'InvoiceDetails',
};

export default enTranslation;
