import { SortDirection } from '../../../../utils/constants';
import { updateEndDate, updateStartDate } from '../../../../utils/dateTimeUtls';
import { createSearchPageSlice } from '../../../Generic/SearchPage/slices/searchPageSlice';

export const filterFields = {
  JobName: 'JobName',
  DateReceivedTo: 'DateReceivedTo',
  DateReceivedFrom: 'DateReceivedFrom',
  DateCompletedTo: 'DateCompletedTo',
  DateCompletedFrom: 'DateCompletedFrom',
  JobStatus: 'JobStatus',
  Location: 'Location',
  Client: 'Client',
  Project: 'Project',
  JobDescription: 'JobDescription',
  ProductGrade: 'ProductGrade',
  OrderNumber: 'OrderNumber',
  ShipmentID: 'ConsignmentNote'
};

const initialFilterState = {
  JobStatus: '',
  DateReceivedFrom: updateStartDate(new Date(new Date().setMonth(new Date().getMonth() - 1))).toISOString(),
  DateReceivedTo: updateEndDate(new Date()).toISOString(),
  DateCompletedFrom: '',
  DateCompletedTo: '',
  DobStatusName: '',
  Location: null,
  Client: null,
  Project: null,
  JobDescription: '',
  ProductGrade: null,
  OrderNumber: '',
  ShipmentID: ''
};

const initialPageState = {
  page: 0,
  pageSize: 20,
  sortColumn: 'CreatedOn',
  sortDirection: SortDirection.Desc,
};

export const sliceName = 'jobSearch';
const jobSearchSlice = createSearchPageSlice({
  sliceName,
  filterFields,
  initialFilterState,
  initialPageState
});

export const jobSearchActions = jobSearchSlice.actions;
export default jobSearchSlice.reducer;
